// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-templates-about-jsx": () => import("./../../../src/templates/About.jsx" /* webpackChunkName: "component---src-templates-about-jsx" */),
  "component---src-templates-motorcycle-jsx": () => import("./../../../src/templates/Motorcycle.jsx" /* webpackChunkName: "component---src-templates-motorcycle-jsx" */),
  "component---src-templates-post-jsx": () => import("./../../../src/templates/Post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */),
  "component---src-templates-tech-jsx": () => import("./../../../src/templates/Tech.jsx" /* webpackChunkName: "component---src-templates-tech-jsx" */)
}

